@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');


@font-face {
  font-family: 'Proxima Nova Regular';
  src: url('./fonts/ProximaNova-Regular.eot');
  src: url('./fonts/ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),
  url('./fonts/ProximaNova-Regular.woff') format('woff'),
  url('./fonts/ProximaNova-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  }

body {
  margin: 0;
  font-family: 'Proxima Nova Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: black;
}


textarea.ant-input {
  max-width: 100%;
  height: 165px;
}

.ant-table-thead > tr > th {
  font-weight: 750;
}

.ant-checkbox-wrapper:hover .ant-checkbox.ant-checkbox-checked::after {
  visibility: hidden;
}

.ant-table-pagination.ant-pagination {
  margin: 16px 1.6px 16px 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ql-editor{
  min-height: 200px;
  max-height: 700px;
}
